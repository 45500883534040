import { Slider } from "@kobalte/core/slider"
import { For, createMemo, createSignal } from "solid-js"
import {
	bodyClass,
	coinRecipe,
	coinsClass,
	computerClass,
	informationClass,
	layerRecipe,
	layersClass,
	moneyCounterClass,
	screenClass,
	screenGlassClass,
	sliderBoxClass,
	sliderRangeClass,
	sliderRootClass,
	sliderThumbClass,
	sliderTrackClass,
	urlsCounterClass,
} from "./calculator.css"

function Layer() {
	return (
		<svg
			width="115"
			height="71"
			viewBox="0 0 115 71"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>Layer</title>
			<path
				d="M63.063 67.47a11.14 11.14 0 0 1-11.143 0L4.567 40.34a5.57 5.57 0 0 1 0-9.694L51.92 3.516a11.14 11.14 0 0 1 11.142 0l47.353 27.13a5.57 5.57 0 0 1 2.825 4.847 5.57 5.57 0 0 1-2.825 4.847z"
				fill="#361941"
				stroke-width="3"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	)
}

function Money() {
	return (
		<svg
			width="23"
			height="23"
			viewBox="0 0 23 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>Money</title>
			<path
				d="M17.403 10.158a6 6 0 1 1-7.75 7.63m-3.34-12h1v4m8.71 3.88.7.71-2.82 2.82m-.59-9.41a6 6 0 1 1-12 0 6 6 0 0 1 12 0"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	)
}

export function Calculator() {
	const [urls, setUrls] = createSignal([1_000])
	const money = createMemo(() => {
		if (urls()[0] <= 2_000) return 0
		if (urls()[0] <= 20_000) return 10

		return urls()[0] / 2_000
	})
	const layerCounter = createMemo(() => urls()[0] / 10_000)
	const coinsCounter = createMemo(() => Math.floor(urls()[0] / 30_000))

	return (
		<div class={computerClass}>
			<div class={screenClass}>
				<div class={screenGlassClass}>
					<div class={layersClass}>
						<For each={Array.from({ length: 9 })}>
							{(_color, index) => (
								<div
									class={layerRecipe({
										active: index() <= layerCounter(),
									})}
								>
									<Layer />
								</div>
							)}
						</For>
					</div>
					<div class={informationClass}>
						<div class={moneyCounterClass}>
							<span>
								{new Intl.NumberFormat("en-US", {
									style: "currency",
									currency: "EUR",
								}).format(money())}
							</span>
							<span class={coinsClass}>
								<For each={Array.from({ length: 3 })}>
									{(_color, index) => (
										<div
											class={coinRecipe({
												active: index() < coinsCounter(),
											})}
										>
											<Money />
										</div>
									)}
								</For>
							</span>
						</div>
						<div class={urlsCounterClass}>
							<span>{new Intl.NumberFormat().format(urls()[0])}</span>
							<span>{urls()[0] === 1 ? "url" : "urls"}</span>
						</div>
					</div>
				</div>
			</div>
			<div class={bodyClass}>
				<Slider
					value={urls()}
					onChange={setUrls}
					minValue={1_000}
					maxValue={100_000}
					step={1_000}
					class={sliderRootClass}
				>
					<div class={sliderBoxClass}>
						<Slider.Track class={sliderTrackClass}>
							<Slider.Fill class={sliderRangeClass} />
							<Slider.Thumb class={sliderThumbClass}>
								<Slider.Input />
							</Slider.Thumb>
						</Slider.Track>
					</div>
				</Slider>
			</div>
		</div>
	)
}
